
export const SideImages = () => {
  return (
    <>
      <div className="images-container">
      </div>
    </>
  )
  {/* <img src="./opt-home-1.png" alt="Home 1" />
  <img src="./opt-home-2.png" alt="Home 2" />
  <img src="./opt-home-3.png" alt="Home 3" /> */}
}