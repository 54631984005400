import { ArrowRight } from "react-bootstrap-icons"
import { Footer } from "./Footer"
import { SideImages } from "./SideImages"
import { Link } from "react-router-dom"

export const Services = () => {
  return (
    <>
      <div className="main-container">
        <main className="p-5">
          <Link to={ '/' }>
            <div className="logo-container pb-5 mb-5">
              <img src="/main-logo.png" alt="Brigge Home Investments" />
            </div>
          </Link>
          <h1 className="thin">
            Services
          </h1>
          <p className="pb-5 mb-5">
            <strong>Bridge Home Investments</strong> is your one-stop-shop for all your construction needs. 
            We offer a wide variety of services, such as home remodeling, custom 
            construction, and new home builds.
            Our team of experienced professionals will work with you to create the 
            perfect space for you and your family.
            We strive to provide the best customer service in the industry, making sure 
            that you are completely satisfied with our work.
          </p>
          <div className="contact-call">
            <Link to={"/contact"}>
              <p>
                Contact us today to get started on <br />
                your dream home! <br />
                <ArrowRight />
              </p>
            </Link>
          </div>
        </main>
        <div className="col-4">
          <SideImages></SideImages>
        </div>
      </div>
      <Footer></Footer>
    </>
  )
}