import { Link } from "react-router-dom";
import { Footer } from "./Footer";
import { SideImages } from "./SideImages";

export const Home = () => {
  return (
    <>
      <div className="main-container">
        <main className="p-5 col-md-8 col-sm-12">
          <div className="logo-container pb-5 mb-5">
            <img src="/main-logo.png" alt="Brigge Home Investments" />
          </div>
          <p className="pb-5 mb-5">
            Bridge Home Investments is a construction business focused on helping you 
            build lasting financial success through quality residencial 
            construction projects.<br />
            With a commitment to excellence and customer satisfaction, Bridge Home 
            Investments will make sure your building project is completed on time and on 
            budget with the highest quality materials and craftsmanship.
          </p>
          <nav className="home-nav">
            <Link to={"/services"}>
              <span>+</span>
              Services
            </Link>
            <Link to={"/contact"}>
              <span>+</span>
              Contact
            </Link>
          </nav>
        </main>
        <div className="col-md-3 col-sm-12">
          <SideImages></SideImages>
        </div>
      </div>
      <Footer></Footer>
    </>
  )
}