import { Envelope, Facebook, Instagram } from "react-bootstrap-icons"
import { Footer } from "./Footer"
import { Link } from "react-router-dom"

export const Contact = () => {
  return (
    <>
      <div className="main-container contact">
        <Link to={ '/' }>
          <div className="logo-container contact pb-5 mb-5">
            <img src="/main-logo.png" alt="Brigge Home Investments" />
          </div>
        </Link>

        <div className="contact-content col-6">
          <h1 className="thin">Contact</h1>
          <p>
            Get in touch with us! <br />
            Here in <strong>Bridge<span>Home</span>Investments </strong>  
            we’ll build yor dreams.
          </p>
        </div>
        <main className="row mb-5 pb-5">
          <div className="col-md-6 col-sm-12">
            <div className="social-row">
              <Facebook />
              <span>bridgehomeinvestments</span>
            </div>
            <div className="social-row">
              <Instagram />
              <span>@bridgehome23</span>
            </div>
            <div className="social-row">
              <Envelope />
              <span>rac@bridgehomellc.com</span>
            </div>
          </div>
          <div className="address col-md-6 col-sm-12">
            <h3>Address</h3>
            <p>
              8865 Commodity Cir Ste <br />
              12-103 PMB <br />
              Orlando - FL - 32819
            </p>
            <h3>Phone</h3>
            <p>407 965 7524</p>
          </div>
        </main>
      </div>
      <Footer />
    </>
  )
}